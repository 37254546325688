import { Injectable } from '@angular/core';
import { Category, SubCategory } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Service to get  the categories and subcategories related to a company
 *
 * @export
 * @class CategoryService
 */
@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  /**
   * Listen to direct change messages
   *
   * @private
   * @type {BehaviorSubject<string>}
   * @memberof CategoryService
   */
  private directChange: BehaviorSubject<string> = new BehaviorSubject(null);

  /**
   * Don't expose BehaviorSubject directly, instead convert it to an Observable
   *
   * @memberof CategoryService
   */
  directChange$ = this.directChange.asObservable();

  /**
   * Creates an instance of CategoryService.
   * @param {HttpClient} http
   * @memberof CategoryService
   */
  constructor(private http: HttpClient) {}

  /**
   * Returns all categories
   *
   * @returns {Promise<Category[]>}
   * @memberof CategoryService
   */
  getCategories(): Promise<Category[]> {
    return this.http.get<Category[]>(`categories`).toPromise();
  }

  /**
   * Returns all the subcategories related to the category param
   *
   * @param {Category} category
   * @returns {Promise<SubCategory[]>}
   * @memberof CategoryService
   */
  getSubCategories(category: Category): Promise<SubCategory[]> {
    return this.http.get<SubCategory[]>(`categories/${category.id}/subcategories`).toPromise();
  }

  /**
   * Communicate to others components that this category/subcategory includes direct change
   *
   * @param {string} directChange
   * @memberof CategoryService
   */
  notifyDirectChange(directChange: string): void {
    this.directChange.next(directChange);
  }
}
