import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CheckAppKeyGuard } from './guards/check-app-key.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'sta-locator' },
  {
    path: 'sta-locator',
    loadChildren: () => import('./pages/sta-locator/sta-locator.module').then((m) => m.StaLocatorModule),
    canActivate: [CheckAppKeyGuard],
    data: ['sta-locator'],
  },
  {
    path: 'sta-locator-callcenter',
    loadChildren: () => import('./pages/sta-locator/sta-locator.module').then((m) => m.StaLocatorModule),
    canActivate: [CheckAppKeyGuard],
    data: ['sta-locator-callcenter'],
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  { path: '**', redirectTo: '/not-found' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
