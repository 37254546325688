import { Injectable } from '@angular/core';
/**
 * Service to validate app key
 *
 * @export
 * @class AppKeyValidatorService
 */
@Injectable({
  providedIn: 'root',
})
export class AppKeyValidatorService {
  /**
   * MD5 hash format
   *
   * @private
   * @type {RegExp}
   * @memberof AppKeyValidatorService
   */
  private readonly MD5_REGEXP: RegExp = /[a-fA-F0-9]{32}/;

  /**
   * Validates if the key is present and is hash with MD5 format
   *
   * @param {string} key
   * @returns
   * @memberof AppKeyValidatorService
   */
  validateAppKey(key: string) {
    return key && this.MD5_REGEXP.test(key);
  }
}
