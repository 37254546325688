import { Injectable } from '@angular/core';
import { TechnicalService, TechnicalServiceFilter } from '../interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PlaceService } from './place.service';
/**
 * Service to manage the Authorized Technical Services
 *
 * @export
 * @class TechnicalServiceService
 */
@Injectable({
  providedIn: 'root',
})
export class TechnicalServiceService {
  /**
   * Creates an instance of TechnicalServiceService.
   * @param {HttpClient} http
   * @memberof TechnicalServiceService
   */
  constructor(private http: HttpClient, private placeService: PlaceService) {}

  /**
   * Search technical services based on the filter
   *
   * @param {TechnicalServiceFilter} filter
   * @returns {Promise<TechnicalService[]>}
   * @memberof TechnicalServiceService
   */
  search(filter: TechnicalServiceFilter): Promise<TechnicalService[]> {
    const params = new HttpParams()
      .set('categoryId', `${filter.subCategory ? filter.subCategory.id : filter.category.id}`)
      .set('lat', `${filter.coords.lat}`)
      .set('lng', `${filter.coords.lng}`)
      .set ('postalCode', this.placeService.postalCode.getValue());
    return this.http
      .get<TechnicalService[]>(`technical-services/find`, { params })
      .toPromise();
  }

  /**
   * Search technical services based on the filter
   *
   * @param {TechnicalServiceFilter} filter
   * @returns {Promise<TechnicalService[]>}
   * @memberof TechnicalServiceService
   */
  searchRapairElements(filter: TechnicalServiceFilter): Promise<TechnicalService[]> {
    const params = new HttpParams()
      .set('element', `${filter.elementFix ? filter.elementFix.element : ''}`)
      .set('lat', `${filter.coords.lat}`)
      .set('lng', `${filter.coords.lng}`);

    return this.http
      .get<TechnicalService[]>(`technical-services/findByRepairingElement`, { params })
      .toPromise();
  }
}
