import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
/**
 * It is in charge of concatenating the base endpoint to each Request that is made.
 *
 * @export
 * @class HttpEndpointInterceptor
 */
@Injectable({
  providedIn: 'root',
})
export class HttpEndpointInterceptor implements HttpInterceptor {
  /**
   * Intercept all Http Requests and add base endpoint url
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof HttpErrorInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('.json')) {
      return next.handle(request);
    } else {
      const req = request.clone({
        url: `${environment.endpoints.middleware}/${request.url}`,
      });

      return next.handle(req);
    }
  }
}
