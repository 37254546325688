// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/** Environment properties */
export const environment = {
  name: 'dev',
  production: false,

  /** General http config */
  http: {
    timeout: 5000,
  },

  /** Backend integration */
  endpoints: {
    middleware: 'https://mapa-sta.testoke.newsan.com.ar/api',
     // middleware: 'http://localhost:3000',
  },

  /** Google Maps configuration */
  google: {
    maps: {
      lat: -34.6156625,
      lng: -58.5033387,
      zoom: 12,
      maxZoom: 17,
      apiKey: 'AIzaSyBKctPa1hAYa7-_KT8fkIIQKh11r8lpUHM',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
