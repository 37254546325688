import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Messages related to Http Status Codes
 *
 * @interface MessageError
 */
interface MessageError {
  [id: number]: {
    message: string;
  };
}

/**
 * Manage Http error services
 *
 * @export
 * @class ErrorService
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  /**
   * Códigos y mensajes de errores
   *
   * @private
   * @static
   * @type {MessageError}
   * @memberof ErrorService
   */
  private static ERRORS: MessageError = {
    0: { message: 'No hay conexión a internet' },
    403: { message: 'App-Key inválida' },
    404: { message: 'El servicio no se encuentra disponible' },
    500: { message: 'Ha ocurrido un error' },
  };

  /**
   * HTTP Status Code when there is a generic error
   *
   * @private
   * @static
   * @type {number}
   * @memberof ErrorService
   */
  private static DEFAULT_STATUS_CODE: number = 500;
  /**
   * HTTP Status Code when there is no internet
   *
   * @private
   * @static
   * @type {number}
   * @memberof ErrorService
   */
  private static NO_INTERNET_STATUS_CODE: number = 0;

  /**
   * Returns client error
   *
   * @memberof ErrorService
   */
  getClientError() {}

  /**
   * Returns server error
   *
   * @param {HttpErrorResponse} error
   * @returns
   * @memberof ErrorService
   */
  getServerError(error: HttpErrorResponse) {
    const statusCode: number = error.status;

    if (this.isOnline || ErrorService.ERRORS[statusCode]) {
      return ErrorService.ERRORS[ErrorService.DEFAULT_STATUS_CODE].message;
    } else {
      return ErrorService.ERRORS[statusCode].message;
    }
  }

  /**
   * Returns timeout error
   *
   * @returns {string}
   * @memberof ErrorService
   */
  getTimeoutError(): string {
    if (this.isOnline) {
      return ErrorService.ERRORS[ErrorService.DEFAULT_STATUS_CODE].message;
    } else {
      return ErrorService.ERRORS[ErrorService.NO_INTERNET_STATUS_CODE].message;
    }
  }

  /**
   * //TODO: mover a un servicio propio de conexión
   * Verifica si el dispositivo tiene conexión a internet
   *
   * @private
   * @returns
   * @memberof ErrorService
   */
  private isOnline() {
    return navigator.onLine;
  }
}
