import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Settings } from './../interfaces';

/**
 * Manage Brand Settings
 *
 * @export
 * @class SettingsService
 */
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  /**
   * Brand settings
   *
   * @private
   * @type {Settings}
   * @memberof SettingsService
   */
  private settings: Settings;

  /**
   * Creates an instance of SettingsService.
   * @param {HttpClient} http
   * @memberof SettingsService
   */
  constructor(private http: HttpClient) {}

  /**
   * Retrieves and caches Brand Settings
   *
   * @returns {Promise<Settings>}
   * @memberof SettingsService
   */
  async getSettings(): Promise<Settings> {
    if (!this.settings) {
      this.settings = await this.http.get<Settings>(`brand/settings`).toPromise();
    }

    return this.settings;
  }
}
