import { Injectable } from '@angular/core';
import { NwbAlertConfig, NwbAlertService } from '@wizishop/ng-wizi-bulma';
/**
 * Notification message wrapper service
 *
 * @export
 * @class NotificationService
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  /**
   * Creates an instance of NotificationService.
   * @param {NwbAlertService} nwbAlert
   * @memberof NotificationService
   */
  constructor(private nwbAlert: NwbAlertService) {}

  /**
   * Present an alert to the user
   *
   * @param {string} message
   * @param {string} [color='is-danger']
   * @memberof NotificationService
   */
  presentAlert(message: string, color: string = 'is-info', position: string = 'is-bottom', duration: number = 5000) {
    const alertConfig: NwbAlertConfig = {
      message,
      duration,
      position,
      color,
    };

    this.nwbAlert.open(alertConfig);
  }
}
