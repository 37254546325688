import { Injectable } from '@angular/core';
import { Category, SubCategory } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ElementFix } from '../interfaces/element-fix.interface';

/**
 * Service to get  the items to repair.
 *
 * @export
 * @class RepairItemService
 */
@Injectable({
  providedIn: 'root',
})
export class RepairItemService {
  /**
   * Listen to direct change messages
   *
   * @private
   * @type {BehaviorSubject<string>}
   * @memberof RepairItemService
   */
  private directChange: BehaviorSubject<string> = new BehaviorSubject(null);

  /**
   * Don't expose BehaviorSubject directly, instead convert it to an Observable
   *
   * @memberof RepairItemService
   */
  directChange$ = this.directChange.asObservable();

  /**
   * Creates an instance of CategoryService.
   * @param {HttpClient} http
   * @memberof RepairItemService
   */
  constructor(private http: HttpClient) {}

  /**
   * Returns all categories
   *
   * @returns {Promise<Category[]>}
   * @memberof RepairItemService
   */
  getElementsFix(): Promise<ElementFix[]> {
    return this.http.get<ElementFix[]>(`categories/subcategories`).toPromise();
  }

  /**
   * Communicate to others components that this category/subcategory includes direct change
   *
   * @param {string} directChange
   * @memberof RepairItemService
   */
  notifyDirectChange(directChange: string): void {
    this.directChange.next(directChange);
  }
}
