import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core'
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Manage place parameters
 *
 * @export
 * @class PlaceService
 */
@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  /**
   * User postal code
   *
   * @private
   * @type {BehaviorSubject<string>}
   * @memberof PlaceService
   */
  postalCode: BehaviorSubject<string> = new BehaviorSubject('');

  /**
   * User postal code observable
   *
   * @type {Observable<string>}
   * @memberof PlaceService
   */
   postalCode$: Observable<string> = this.postalCode.asObservable();
  constructor() { }

  /**
   * Update postal code
   *
   * @param {string} zoom
   * @memberof PlaceService
   */
   updatePostalCode(postalCode: string) {
    this.postalCode.next(postalCode);
  }
}
