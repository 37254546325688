import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppKeyValidatorService } from '../services';

/**
 * Intercept all Http requests and adds the App Key in the Http Headers
 *
 * @export
 * @class HttpAppKeyInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable({
  providedIn: 'root',
})
export class HttpAppKeyInterceptor implements HttpInterceptor {
  /**
   * Cache the app key
   *
   * @private
   * @type {string}
   * @memberof HttpAppKeyInterceptor
   */
  private appKey: string;

  /**
   * Creates an instance of HttpAppKeyInterceptor.
   * @param {AppKeyValidatorService} appKeyValidatorService
   * @memberof HttpAppKeyInterceptor
   */
  constructor(private appKeyValidatorService: AppKeyValidatorService) {}

  /**
   * If it's present adds the app key to the http headers
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof HttpAppKeyInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appKey: string = this.getAppKey();
    const isAppKeyValid: boolean = this.appKeyValidatorService.validateAppKey(appKey);

    if (isAppKeyValid) {
      const request = req.clone({
        setHeaders: {
          'app-key': `${appKey}`,
        },
      });

      return next.handle(request);
    } else {
      return next.handle(req);
    }
  }

  /**
   * Parse and returns the app key
   *
   * @returns
   * @memberof HttpAppKeyInterceptor
   */
  getAppKey() {
    if (!this.appKey) {
      const params = new URLSearchParams(window.location.search);
      this.appKey = params.get('key');
    }

    return this.appKey;
  }
}
