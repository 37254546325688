import { Injectable } from '@angular/core';
import { Coords } from '../interfaces';
import { Position } from '@angular/compiler';
/**
 * Service to get current location and do some geolocation calcs∆
 *
 * @export
 * @class GeolocationService
 */
@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  /**
   * Returns current location if available
   *
   * @returns {Promise<Coords>}
   * @memberof GeolocationService
   */
  getCurrentLocation(): Promise<Coords> {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        const options: PositionOptions = {
          // precise location
          enableHighAccuracy: true,
          // 5 seconds
          maximumAge: 5000,
          // 1 minute
          timeout: 60000,
        };

        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords: Coords = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            resolve(coords);
          },
          reject,
          options,
        );
      } else {
        reject('Geolocation not supported');
      }
    });
  }
}
