import { Injectable } from '@angular/core';
import { Params, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppKeyValidatorService } from '../services';
/**
 * Check if the app key is valid
 *
 * @export
 * @class CheckKeyGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class CheckAppKeyGuard implements CanActivate {
  /**
   * Creates an instance of CheckAppKeyGuard.
   * @param {Router} router
   * @param {AppKeyValidatorService} appKeyValidatorService
   * @memberof CheckAppKeyGuard
   */
  constructor(private router: Router, private appKeyValidatorService: AppKeyValidatorService) {}

  /**
   * The current url can be activated only if the app key is passed as parameter
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {(boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>)}
   * @memberof CheckKeyGuard
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const params: Params = route.queryParams;
    const isAppKeyValid: boolean = this.appKeyValidatorService.validateAppKey(params.key);

    if (!isAppKeyValid) {
      this.router.navigateByUrl('/not-found');
    }

    return isAppKeyValid;
  }
}
