import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorService, NotificationService } from '../services';
/**
 * Intercept all Http errors and show a notification
 *
 * @export
 * @class HttpErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * Creates an instance of HttpErrorInterceptor.
   * @param {ErrorService} errorService
   * @param {NotificationService} notificationService
   * @memberof HttpErrorInterceptor
   */
  constructor(private errorService: ErrorService, private notificationService: NotificationService) {}

  /**
   * Intercepta todas los requests y responses Http
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof HttpErrorInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(environment.http.timeout),
      tap(
        () => {},
        (response) => this.handleError(response),
      ),
    );
  }

  /**
   * Handle specificar http errors
   *
   * @private
   * @param {any} error
   * @returns
   *
   * @memberof HttpErrorInterceptor
   */
  private handleError(error: Error | HttpErrorResponse) {
    // Intercepta los responses http que contienen errores
    if (error instanceof HttpErrorResponse) {
      // Server Error
      this.handleServerError(error);
    } else if (error instanceof TimeoutError) {
      // Timeout Error
      this.handleTimeoutError();
    } else {
      // Client Error
      this.handleClientError(error);
    }

    return throwError(error);
  }

  /**
   * Show an specific error on client error
   *
   * @private
   * @param {Error} error
   * @memberof HttpErrorInterceptor
   */
  private handleClientError(error: Error) {
    console.log('Client error', error);
  }

  /**
   * Show an specific error on http timeout
   *
   * @private
   * @memberof HttpErrorInterceptor
   */
  private handleTimeoutError() {
    const message: string = this.errorService.getTimeoutError();
    this.notificationService.presentAlert(message, 'is-danger');
  }

  /**
   * Show an specific error on server http error
   *
   * @private
   * @param {HttpErrorResponse} error
   * @memberof HttpErrorInterceptor
   */
  private handleServerError(error: HttpErrorResponse) {
    console.log('Processing http error', error);

    const message: string = this.errorService.getServerError(error);
    this.notificationService.presentAlert(message, 'is-danger');
  }
}
