import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Coords } from '../interfaces';
import { environment } from 'src/environments/environment';
import { LatLngBounds } from '@agm/core';

/**
 * Manage map parameters
 *
 * @export
 * @class MapService
 */
@Injectable({
  providedIn: 'root',
})
export class MapService {
  /**
   * Map instance
   *
   * @type {*}
   * @memberof MapService
   */
  map: any;

  isMapReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Current maps coords
   *
   * @private
   * @type {BehaviorSubject<Coords>}
   * @memberof MapService
   */
  private coords: BehaviorSubject<Coords> = new BehaviorSubject({
    lat: environment.google.maps.lat,
    lng: environment.google.maps.lng,
  });

  /**
   * Map zoom
   *
   * @private
   * @type {BehaviorSubject<number>}
   * @memberof MapService
   */
  private zoom: BehaviorSubject<number> = new BehaviorSubject(environment.google.maps.zoom);

  /**
   * Map coords observable
   *
   * @type {Observable<Coords>}
   * @memberof MapService
   */
  coords$: Observable<Coords> = this.coords.asObservable();

  /**
   * Map zoom observable
   *
   * @type {Observable<number>}
   * @memberof MapService
   */
  zoom$: Observable<number> = this.zoom.asObservable();

  /**
   * Update current coords
   *
   * @param {Coords} coords
   * @memberof MapService
   */
  updateCoords(coords: Coords) {
    this.coords.next(coords);
  }

  /**
   * Update current map zoom
   *
   * @param {number} zoom
   * @memberof MapService
   */
  updateZoom(zoom: number) {
    this.zoom.next(zoom);
  }

  /**
   * Pan map to specific coords
   *
   * @param {Coords} coords
   * @memberof MapService
   */
  panTo(coords: Coords) {
    if (this.map) {
      this.map.panTo(coords);
    }
  }

  /**
   * Change map zoom acording to coords bounds
   *
   * @param {LatLngBounds} bounds
   * @memberof MapService
   */
  fitBounds(bounds: LatLngBounds) {
    if (this.map) {
      this.map.fitBounds(bounds);
    }
  }

  setMap(map: any) {
    if (!!map) {
      this.map = map;
      this.isMapReady.next(true);
    }
  }
}
