import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpEndpointInterceptor, HttpAppKeyInterceptor, HttpErrorInterceptor } from './interceptors';
import { NwbAlertModule } from '@wizishop/ng-wizi-bulma';
import { PlaceService } from './services';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google.maps.apiKey,
      libraries: ['places'],
    }),
    NwbAlertModule,
  ],
  providers: [
    // Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: HttpEndpointInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAppKeyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },

    // I18N
    { provide: LOCALE_ID, useValue: 'es-ES' },
    PlaceService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
